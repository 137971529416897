import { Base } from '@models/base';
import { Action } from '@models/actions';
import { FirmwareState, FirmwareType, FirmwareKind } from '@models/firmware';
import {BriefInfo} from "@models/informational";

export interface DeviceReportTotals {
  numberOfUsers: number;
  averageNumberOfDevices: number;
  onlineNow: number;
  needToUpdateCount: number;
}

export interface DeviceReportDataTotals {
  deviceType: string;
  model: string;
  totalNumberOfDevices: number;
  countOfPaired: number;
  countOfUnpaired: number;
  countOfBeenOnline: number;
}

export interface DeviceReportDataRow {
  deviceType: string;
  model: string;
  totalNumberOfDevices: number;
  countOfPaired: number;
  countOfUnpaired: number;
  countOfBeenOnline: number;
}

export interface DeviceReportData {
  total: DeviceReportDataTotals,
  rows: Array<DeviceReportDataRow>;
}

interface DeviceReportDeviceType {
  deviceTypeId: number;
  modelIds: Array<number>;
}

export interface DeviceReportModels {
  types: Array<DeviceReportDeviceType>;
}

export interface FirmwareReportRow {
  id: number;
  version: string;
  state: FirmwareState;
  type: FirmwareType;
  kind: FirmwareKind;
  createdAt: Date;
  availableFor: number;
  availableForPercent: number;
  installedOn: number;
  deviceTypes: Array<string>;
  deviceModels: Array<string>;
  lastInstalledAt: Date;
}

export enum TestReportTypeCode {
  FUNCTIONALITY = 'FUNCTIONALITY',
  REGRESSION = 'REGRESSION'
}

export interface TestReportType {
  name: string;
  code: TestReportTypeCode;
}

export enum TestReportStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DELETED = 'DELETED',
  DRAFT = 'DRAFT',
}

export interface VersionInfo {
  id: number;
  version: string;
}

export interface TestReport {
  id: number | null;
  fileName: string;
  type: TestReportType;
  modelGroup: Base;
  wifiModuleVersion: VersionInfo;
  eppVersion: number;
  ios: boolean;
  iosVersion: string;
  android: boolean;
  androidVersion: string;
  status: TestReportStatus;
  author: string;
  createdAt: Date;
  updatedBy?: string;
  updatedAt?: Date;
}

export interface TestReportView extends TestReport {
  actions?: Array<Action>;
}

export interface TestReportModel {
  main: boolean;
  model: Base;
  logicConfig: Base;
}

export interface TestReportDetails extends TestReport {
  comment: string;
  models: Array<TestReportModel>;
  masterModel?: Base;
}

export interface TestReportBriefInfo extends BriefInfo {
  status: TestReportStatus;
  byConfig: boolean;
}

export interface TestReportStatisticsLine {
  name: string;
  coverage: number;
}

export interface TestReportStatistics {
  deviceTypeStatisticList: Array<TestReportStatisticsLine>;
  commonCoverage: number;
}
